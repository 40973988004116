<template>
    <section class="d-flex flex-column hc-135px overflow-hidden custom-scroll">
        <tabs :tabs="rutas" class="border-bottom mt-3" />
        <router-view />
    </section>
</template>

<script>
export default {
    data(){
        return{
            rutas: [
                // { titulo: 'Pendientes por gestionar', ruta: 'admin.tesoreria.pendientes-gestionar', can: 'tab1_tesoreria_creditos' },
                // { titulo: 'Vendedores por liquidar', ruta: 'admin.tesoreria.leecheros-liquidar', can: 'tab1_tesoreria_comisiones' },
                { titulo: 'Comisiones pendientes', ruta: 'admin.tesoreria.comisiones.pendientes'},
                { titulo: 'Comisiones pagadas', ruta: 'admin.tesoreria.comisiones-pagadas' },
            ]
        }
    },
}
</script>
